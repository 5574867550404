import {useState, useEffect} from "react";
import type {ReactElement} from "react";
import {useForm} from 'react-hook-form';
import {Coffee, Clock, DollarSign} from 'react-feather';
import {Link} from "@uxu/design-system/components/atoms/link";
import {Input} from "@uxu/design-system/components/molecules/inputs";
import {SectionWithCircle} from "@uxu/design-system/components/templates/section/sectionWithCircle";
import {useSiteConfig} from "@uxu/design-system/hooks/useSiteConfig";
import {LoadingWheel} from "@uxu/design-system/components/atoms/loading";
import {Note} from "@uxu/design-system/components/atoms/note";
import styles from './sectionQuestionnaireContact.module.scss';
import {steps} from './consts';
import type {LeadInput} from './types';

export function SectionQuestionnaireContact(): ReactElement {
    const {config} = useSiteConfig();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState<{ success?: boolean, message?: string }>({});
    const [currentStep, setCurrentStep] = useState(0);

    const {handleSubmit, register, reset, formState: {errors}} = useForm<LeadInput>({
        defaultValues: steps[currentStep].data.reduce((acc, field) => ({
            ...acc, [field.label]: ''
        }), {})
    });

    useEffect(() => {
        reset(steps[currentStep].data.reduce((acc, field) => ({
            ...acc, [field.label]: ''
        }), {}));
    }, [currentStep, reset]);

    const isLastStep = currentStep === steps.length - 1;

    function onSubmit(_data): void {
        if (isLastStep) {
            setIsSubmitting(true);
            try {
                setSubmitStatus({success: true, message: "Niebawem skontaktujemy się z Tobą :)"});
                resetTimeout();
            } catch (error) {
                setSubmitStatus({success: false, message: "Upss... coś poszło nie tak... spróbuj jeszcze raz..."});
                resetTimeout();
            }
        } else {
            setCurrentStep(currentStep + 1);
        }
    }

    function onBack(): void {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    function resetTimeout(): void {
        setTimeout(() => {
            reset();
            setSubmitStatus({});
            setIsSubmitting(false);
        }, 10000);
    }

    function renderInfo(): ReactElement | null {
        if (isSubmitting) {
            if (submitStatus.success !== undefined) {
                return submitStatus.success
                    ? <Note typ="success">{submitStatus.message}</Note>
                    : <Note typ="error">{submitStatus.message}</Note>;
            }
            return <Note action={<LoadingWheel size={2}/>} typ="warning">Zapisuję...</Note>;

        }
        return null;
    }

    return (
        <SectionWithCircle color="var(--uxu-gradient-blue-tell)" header="Analiza wstępna" id="start" inCircle="0">
            <div className={styles.wrapper}>
                <div className={styles.wrapperQuestionnaire}>
                    <div className={styles.wrapperForm}>
                        {isSubmitting ? renderInfo() : (
                            <>
                                <div className={styles.wrapperHeader}>
                                    <strong>{steps[currentStep].title}</strong>
                                    <p>{steps[currentStep].description}</p>
                                </div>
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit(onSubmit)(e).catch(() => { /* empty */
                                    });
                                }}>
                                    {steps[currentStep].data.map((field) => (
                                        <Input
                                            errorMessage={errors[field.label as "name" | "email" | "secondName" | "phone"] ? field.errorMessage : ""}
                                            key={field.label}
                                            {...register(field.label as "name" | "email" | "secondName" | "phone", {
                                                required: field.isRequire,
                                                pattern: field.pattern
                                            })}
                                            placeholder={field.placeholder}
                                            type={field.type}
                                        />
                                    ))}
                                    <div className={styles.wrapperButtons}>
                                        {currentStep > 0 && (
                                            <button className="btn" onClick={onBack} style={{marginRight: "auto"}}
                                                    type="button">
                                                Wstecz
                                            </button>
                                        )}
                                        <button className="btn primary" type="submit">
                                            {isLastStep ? 'Wyślij' : 'Dalej'}
                                        </button>
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                    <span className={styles.info}>
                        Przesyłając ten formularz, potwierdzam, że przeczytałem i zrozumiałem{' '}
                        <Link href="/privacy-policy" title="Polityka prywatności">
                            politykę prywatności
                        </Link> firmy {config.admin.company?.name}
                    </span>
                </div>
                <div className={styles.wrapperInfo}>
                    <p><Clock size={40}/> W ciągu 72h nasi prawnicy przeanalizują Twoją umowę</p>
                    <p><DollarSign size={40}/> Analiza umowy jest w 100% darmowa</p>
                    <p><Coffee size={40}/> Ciekawy, czy należy Ci się zwrot? Sprawdź :)</p>
                </div>
            </div>
        </SectionWithCircle>
    );
}
