import type {ReactElement} from 'react';
import {LayoutFull} from '@uxu/design-system/components/layout/layoutFull';
import {useSeoConfig} from '@uxu/design-system/hooks/useSeoConfig';
import {footerConfig, headerMenuConfig} from '../config';
import {SectionMediaCoverage} from '../components/template/section/sectionMediaCoverage';
import {SectionWhatItWork} from '../components/template/section/sectionWhatItWork';
import {SectionQuestionnaireContact} from '../components/template/section/sectionQuestionnaireContact';

function Index(): ReactElement {
    const seo = useSeoConfig({});

    return (
        <LayoutFull
            footer={footerConfig}
            headerMenu={headerMenuConfig}
            seo={seo}
        >
            <SectionMediaCoverage/>
            <h4 className="headerSection">JAK TO DZIAŁA?</h4>
            <SectionWhatItWork />
            <h4 className="headerSection">SPRAWDŹ SWOJĄ UMOWĘ</h4>
            <SectionQuestionnaireContact />
        </LayoutFull>
    );
}

export default Index;
